






























import { computed, defineComponent } from '@nuxtjs/composition-api';

export interface Sources {
  default: {
    srcset: string;
  };
  desktop?: {
    media: string;
    srcset: string;
  };
  tablet?: {
    media: string;
    srcset: string;
  };
  mobile?: {
    media: string;
    srcset: string;
  };
}

export default defineComponent({
  name: 'BannerUSERCOM',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup({ blok }) {
    const breakpoints = {
      desktop: 1024,
      tablet: 768,
      mobile: 320,
    };

    const Image = computed(() => blok?.Image?.filename);
    const ImageTablet = computed(() => blok?.ImageTablet?.filename);
    const ImageMobile = computed(() => blok?.ImageMobile?.filename);

    const prepareImageSources = () => {
      const sources: Sources = {
        default: {
          srcset: Image.value,
        },
      };

      if (Image.value) {
        sources.desktop = {
          media: `(min-width:${breakpoints.desktop}px)`,
          srcset: `${Image.value}`,
        };
      }
      if (ImageTablet.value) {
        sources.tablet = {
          media: `(min-width:${breakpoints.tablet}px)`,
          srcset: `${ImageTablet.value}`,
        };
      }
      if (ImageMobile.value) {
        sources.mobile = {
          media: `(min-width:${breakpoints.mobile}px)`,
          srcset: `${ImageMobile.value}`,
        };
      }

      return sources;
    };

    const imageSources = prepareImageSources();

    return {
      imageSources,
    };
  },
});
